import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------
const Sports = lazy(() => import('src/pages/dashboard/sports/sports'));
const EventDetail = lazy(() => import('src/pages/dashboard/sports/event-detail'));
const Graph = lazy(() => import('src/pages/dashboard/sports/graph'));
const Bets = lazy(() => import('src/pages/dashboard/bets/bets'));
const CasinoBets = lazy(() => import('src/pages/dashboard/bets/casino-bets'));
const PreviousBets = lazy(() => import('src/pages/dashboard/bets/previous-bets'));
const BetDetail = lazy(() => import('src/pages/dashboard/bets/bet-detail'));
const CasinoCMS = lazy(() => import('src/pages/dashboard/casino-cms/casino-cms'));
const BetTicker = lazy(() => import('src/pages/dashboard/surveillance/bet-ticker/bet-ticker'));
const SuperAdminList = lazy(()=>import('src/pages/dashboard/users/super-admins/list'));
const SuperAdminAdd = lazy(()=>import('src/pages/dashboard/users/super-admins/add'));
const SuperAdminDetail = lazy(()=>import('src/pages/dashboard/users/super-admins/detail'));
const SuperAdminEdit = lazy(()=>import('src/pages/dashboard/users/super-admins/edit'));

const SessionLogs = lazy(()=>import(`src/pages/dashboard/all-logs/session-logs`));
const MultipleIPLogs = lazy(()=>import(`src/pages/dashboard/all-logs/mulitple-ip-logs`));
const ErrorLogs = lazy(()=>import(`src/pages/dashboard/all-logs/error-logs`));

const TransferList = lazy(()=>import('src/pages/dashboard/transfer/transferList'));
const SiteNotification = lazy(()=>import('src/pages/dashboard/site-notifications/site-notifications'))
const Maintenance = lazy(()=>import('src/pages/dashboard/developer/maintenance/maintenance'))
const DevTools = lazy(()=>import('src/pages/dashboard/developer/dev-tools/dev-tools'))
const CompanyAdminRoles = lazy(()=>import('src/pages/dashboard/users/ca_users/list'))
const CompanyAdminRoleAdd = lazy(()=>import('src/pages/dashboard/users/ca_users/add'))
const CompanyAdminRoleEdit = lazy(()=>import('src/pages/dashboard/users/ca_users/edit'))
const CompanyAdminRoleDetail = lazy(()=>import('src/pages/dashboard/users/ca_users/detail'))
const Clients = lazy(()=>import('src/pages/dashboard/users/clients/list'))
const ClientDetail = lazy(()=>import('src/pages/dashboard/users/clients/detail'))

const MasterController = lazy(()=>import('src/pages/dashboard/settings/master-controller/master-controller'))
const TemplateCustomization = lazy(()=>import('src/pages/dashboard/settings/template-customization/template-customization'))
const TemplateBannerList = lazy(()=>import('src/pages/dashboard/settings/template-customization/banner-list'))

const SiteCMS = lazy(()=>import('src/pages/dashboard/site-cms/list'))

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { path: 'sports', 
        children: [
          {element: <Sports />, index:true},
          { path: ':sportId/:eventId', element: <EventDetail /> },
        ],
      },
      { path: 'graph', 
      children: [
        {element: <Sports />, index:true},
        { path: ':sportId/:eventId', element: <Graph /> },
      ],
    },
      { path: 'bets', 
      children: [
        {element: <Bets />, index:true},
        {path:'casino',element: <CasinoBets />},
        {path:'previous',element: <PreviousBets />},
        { path: ':betId', element: <BetDetail /> },
        ],
      },
      { path: 'users', 
        children: [
          {
            path:'ca_users',
            children:[
              {element: <CompanyAdminRoles />, index:true},
              {path: 'add' ,element: <CompanyAdminRoleAdd />},
              {path: 'edit/:admin_id' ,element: <CompanyAdminRoleEdit />},
              {path: 'detail/:admin_id' ,element: <CompanyAdminRoleDetail />},
            ]
        },
        {
          path:'clients',
          children:[
            {element: <Clients />, index:true},
            {path: 'detail/:user_id',element: <ClientDetail />, index:true},
          ]
        },
        {
          path: 'super_admins',
          children:[
            {index:true,element:<SuperAdminList />},
            {path:'add',element:<SuperAdminAdd />},
            {path:'detail/:admin_id',element:<SuperAdminDetail />},
            {path:'edit/:admin_id',element:<SuperAdminEdit />},
          ]
        },
        ],
      },
      {
        path:'all_logs',
        children:[
          {index:true,path:"session",element:<SessionLogs />},
          {path:"multiple_ip",element:<MultipleIPLogs />},
          {path:"error",element:<ErrorLogs />},
        ]
      },
      {
        path: 'settings',
        children:[
          { path: 'master_controller', element: <MasterController /> },
          { path: 'templates', element: <TemplateCustomization /> },
          { path: 'template/:template_id', element: <TemplateBannerList /> },
        ]
      },
      {
        path: 'developer',
        children:[
          { path: 'maintenance', element: <Maintenance /> },
          { path: 'dev_tools', element: <DevTools /> },
        ]
      },
      {
        path: 'sites',
        children:[
          { path: 'list', element: <SiteCMS /> },
        ]
      },
      { path: 'transfer', element: <TransferList /> },
      { path: 'casino_cms', element: <CasinoCMS /> },
      
      
      { path: 'surveillance' ,element:<BetTicker />},
      { path: 'site_notications', element: <SiteNotification /> },
    ],
  },
];
